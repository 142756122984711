<template>
  <div class="messages">
    <div class="content">
      <div class="header">
        <div class="class">
          <div @click="activeTab = 'all'" :class="{'selected': activeTab === 'all'}" class="type">全部</div>
          <div @click="activeTab = 'mine'" :class="{'selected': activeTab === 'mine'}" class="type">@我的</div>
        </div>
        <el-input v-model.trim="query.remark" clearable placeholder="请输入消息内容" style="width: 400px;">
          <el-button slot="append" @click="handleSearch" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <table-with-pagination
        :tableData="list"
        class="content_block"
        :columnList="columnList"
        :pagination.sync="pagination"
        @current-change="getTableList"
        @sort-change="getTableList"
      />
    </div>
    <!-- 消息详情 -->
    <el-dialog :title="currentItem.businessTypeName" :visible.sync="dialogVisible" width="70vw">
      <div style="height: 50vh; overflow-y: scroll;">{{ currentItem.remark }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { queryPage, updateReadFlag } from '@/api/messages';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { dateFormat } from '@/utils/dateFormat.js';
export default {
  mixins: [
    TablePaginationMixin,
  ],
  watch: {
    activeTab () {
      this.handleSearch();
    },
  },
  data () {
    return {
      activeTab: 'all',
      query: {
        remark: '',
      },
      columnList: [
        {
          tooltip: true,
          label: '事件类型',
          width: '180px',
          prop: 'businessTypeName',
        },
        {
          tooltip: true,
          label: '内容',
          minWidth: '150px',
          prop: 'remark',
          render: (h, {row, index})=>{
            let clickFn = this.handleActionClick.bind(this, row, index);
            let classObj = {'text-primary': !row.readFlag, 'cursor-pointer': row.readFlag};

            return <span class={classObj} onClick={clickFn}>{row.remark}</span>;
          },
        },
        {
          tooltip: true,
          label: '创建时间',
          width: '180px',
          prop: 'createTime',
          format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
      ],
      list: [],
      currentItem: {},
      dialogVisible: false,
    };
  },
  methods: {
    async handleActionClick (item, i) {
      await updateReadFlag({ id: item.id });
      this.list[i].readFlag = 1;
      this.currentItem = item;
      this.dialogVisible = true;
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.list = [];
      this.getTableList();
    },
    async getTableList () {
      let params = {
        ...this.query,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      if (this.activeTab === 'mine') {
        const { employeeMsg } = JSON.parse(this.$local.get('userInfo'));
        params.remindUserId = employeeMsg.id;
      }
      const data = await queryPage(params);
      this.list = data.body.list;
      this.pagination.total = data.body.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.messages {
  background-color: #F2F4F7;
  .content {
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 75px;
    padding: 15px 0;
    .header {
      display: flex;
      align-items: center;
      padding: 0 15px 15px;
      .class {
        margin-right: 25px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #F2F4F7;
        overflow: hidden;
        display: inline-block;
        border-bottom: 1px solid #F2F4F7;;
        cursor: pointer;
        .type {
          display: inline-block;
          color: #5F6A7A;
          padding: 6px 20px;
        }
        .selected {
          color: #237FFA;
          background-color: #E2F3FE;
        }
      }
    }
  }
}
</style>
